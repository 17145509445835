/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `BookingBreakdown` component.
 */
import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { LINE_ITEMS, propTypes } from '../../util/types';

import css from './BookingBreakdown.css';
import config from 'config';

const LineItemUnknownItemsMaybe = props => {
  const { transaction, intl } = props;

  // resolve unknown non-reversal line items
  const items = transaction.attributes.lineItems.filter(
    item =>
      item.code === config.bookingCustomAmount ||
      (LINE_ITEMS.indexOf(item.code) === -1 && !item.reversal)
  );
  const getCustomAmount = items.filter(item => item.code === 'line-item/custom-amount');
  const newUnqArr = items.filter(item => item.code !== 'line-item/custom-amount');
  const newItems = getCustomAmount.concat(newUnqArr);



  return newItems.length > 0 ? (
    <React.Fragment>
      {newItems.map((item, i) => {
        const label = humanizeLineItemCode(item.code);
        const formattedTotal = formatMoney(intl,item.lineTotal);
        console.log("formattedTotal",formattedTotal)
  
        // const quantity = item.quantity.toString();
        return (
          <div className={css.priceWrapper} key={i}>
            {/* <div className={css.lineItem}>
              <span className={css.priceLabel}>{label}</span>
              <span className={css.itemValue}>
                {item.unitPrice.currency}&nbsp;{item.unitPrice.amount}
              </span>
            </div> */}
            <div key={`${i}-item.code`} className={css.lineItem}>
              <span className={css.priceLabel}>
                {/* <FormattedMessage
                  id={'BookingBreakdown.addonsLabel'}
                  values={{ label, quantity }}
                /> */}
                {label}
              </span>
              <span className={css.itemValue}>
                <span>
                  {String(item.lineTotal.amount).includes(".") || String(Math.abs(item.lineTotal.amount)).length === 1
                    ? `${item.lineTotal.currency} ${Math.abs(item.lineTotal.amount).toFixed(2)}`
                    : formattedTotal}
                </span>
              </span>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  ) : null;
};

LineItemUnknownItemsMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnknownItemsMaybe;

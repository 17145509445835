/**
 * Booking breakdown estimation
 *
 * Transactions have payment information that can be shown with the
 * BookingBreakdown component. However, when selecting booking
 * details, there is no transaction object present and we have to
 * estimate the breakdown of the transaction without data from the
 * API.
 *
 * If the payment process of a customized marketplace is something
 * else than simply daily or nightly bookings, the estimation will
 * most likely need some changes.
 *
 * To customize the estimation, first change the BookingDatesForm to
 * collect all booking information from the user (in addition to the
 * default date pickers), and provide that data to the
 * EstimatedBreakdownMaybe components. You can then make customization
 * within this file to create a fake transaction object that
 * calculates the breakdown information correctly according to the
 * process.
 *
 * In the future, the optimal scenario would be to use the same
 * transactions.initiateSpeculative API endpoint as the CheckoutPage
 * is using to get the breakdown information from the API, but
 * currently the API doesn't support that for logged out users, and we
 * are forced to estimate the information here.
 */
import React from 'react';
import Decimal from 'decimal.js';
import { types as sdkTypes } from '../../util/sdkLoader';
import { TRANSITION_REQUEST_PAYMENT, TX_TRANSITION_ACTOR_CUSTOMER } from '../../util/transaction';
import { LINE_ITEM_UNITS } from '../../util/types';
import { unitDivisor, convertMoneyToNumber, convertUnitToSubUnit } from '../../util/currency';
import { BookingBreakdown, OrderBreakdown } from '../../components';
import { isInt, isFloat } from 'util/numbers';
import { addonLabelIntoCode } from 'util/data';
import moment from 'moment';
import config from '../../config';

import css from './BookingTimeForm.css';

const { Money, UUID } = sdkTypes;

const estimatedTotalPrice = (
  currentUserChargebeeId,
  currentUserIsCorporateUser,
  currentUserEndDate,
  todayDate,
  unitPrice,
  unitCount,
  priceAddons,
  discountPriceAddons,
  seats,
  unitType
) => {
  const numericPrice = convertMoneyToNumber(unitPrice);
  const addonsTotalPrice = priceAddons
    ? priceAddons
      .map(i => convertMoneyToNumber(i.price))
      .reduce((sum, current) => sum + current * unitCount, 0)
    : 0;

  const discountAddonsTotalPrice = discountPriceAddons
    ? discountPriceAddons
      .map(i => {
        const discountPerUnit = (numericPrice * Number(i.amount)) / 100;
        return unitCount >= 1
          ? discountPerUnit
          :discountPerUnit * unitCount;
      })
      .reduce((sum, current) => sum + current, 0)
    : 0;

  const chargebeeDiscount =
    currentUserChargebeeId &&
      todayDate < currentUserEndDate &&
      unitType !== config.bookingCustomAmount
      ? (numericPrice * 10) / 100
      : 0;

  const corporateDiscount =
    currentUserIsCorporateUser && !chargebeeDiscount && unitType !== config.bookingCustomAmount
      ? (numericPrice * 10) / 100
      : 0;

  const numericTotalPrice = new Decimal(numericPrice)
    .times(unitCount)
    .times(seats)
    .add(addonsTotalPrice)
    .minus(discountAddonsTotalPrice)
    .minus(corporateDiscount)
    .minus(chargebeeDiscount)
    .toDecimalPlaces(2)
    .toNumber();

  return new Money(
    convertUnitToSubUnit(numericTotalPrice, unitDivisor(unitPrice.currency)),
    unitPrice.currency
  );
};

const estimatedMainPrice = (unitPrice, quantity, seats) => {
  const numericPrice = convertMoneyToNumber(unitPrice);
  const total = new Decimal(numericPrice)
    .times(quantity)
    .times(seats)
    .toDecimalPlaces(2)
    .toNumber();
  return new Money(
    convertUnitToSubUnit(total, unitDivisor(unitPrice.currency)),
    unitPrice.currency
  );
};

const estimatedTransaction = (
  currentUserChargebeeId,
  currentUserIsCorporateUser,
  currentUserEndDate,
  todayDate,
  unitType,
  bookingStart,
  bookingEnd,
  unitPrice,
  quantity,
  priceAddons,
  discountPriceAddons,
  seats,
  isProduct
) => {
  const now = new Date();
  const numericPrice = convertMoneyToNumber(unitPrice);
  const newPriceAddon = priceAddons
    ? priceAddons.map(val => {
      return { ...val, price: new Money(val.price.amount * seats, val.price.currency) };
    })
    : [];
  const totalPrice = estimatedTotalPrice(
    currentUserChargebeeId,
    currentUserIsCorporateUser,
    currentUserEndDate,
    todayDate,
    unitPrice,
    quantity,
    newPriceAddon,
    discountPriceAddons,
    seats,
    unitType
  );

  const payinTotal = new Money(totalPrice.amount, unitPrice.currency);
  const payoutTotal = new Money(totalPrice.amount, unitPrice.currency);
  const listOfFormattedPriceAddons = priceAddons
    ? priceAddons.map(addon => ({
      code: addonLabelIntoCode(addon.label),
      includeFor: ['customer', 'provider'],
      unitPrice: addon.price,
      quantity: new Decimal(quantity),
      lineTotal: estimatedMainPrice(addon.price, quantity, seats),
      reversal: false,
    }))
    : [];

  const listOfFormattedDiscountPriceAddons = discountPriceAddons
    ? discountPriceAddons.map(addon => {
      const discountValue = (addon.amount * numericPrice) / 100;
      const adjustedDiscountValue = quantity > 1 ? discountValue * -1 : discountValue * -1 * quantity;

      return {
        code: addonLabelIntoCode(addon.label),
        includeFor: ['customer', 'provider'],
        unitPrice: new Money(adjustedDiscountValue, unitPrice.currency),
        lineTotal: new Money(adjustedDiscountValue, unitPrice.currency),
        reversal: false,
        quantity: new Decimal(quantity),
      };
    })
    : [];

  const chargebeeAddons =
    currentUserChargebeeId &&
      todayDate < currentUserEndDate &&
      unitType !== config.bookingCustomAmount
      ? [
        {
          code: addonLabelIntoCode('subscription discount'),
          includeFor: ['customer', 'provider'],
          unitPrice: new Money((unitPrice.amount * -10) / 100, unitPrice.currency),
          lineTotal: new Money((unitPrice.amount * -10) / 100, unitPrice.currency),
          reversal: false,
          quantity: 1,
        },
      ]
      : [];

  const corporateAddons =
    currentUserIsCorporateUser && !chargebeeAddons.length && unitType !== config.bookingCustomAmount
      ? [
        {
          code: addonLabelIntoCode('corporate user discount'),
          includeFor: ['customer', 'provider'],
          unitPrice: new Money((unitPrice.amount * -10) / 100, unitPrice.currency),
          lineTotal: new Money((unitPrice.amount * -10) / 100, unitPrice.currency),
          reversal: false,
          quantity: 1,
        },
      ]
      : [];

  return isProduct
    ? {
      id: new UUID('estimated-transaction'),
      type: 'transaction',
      attributes: {
        productBooking: true,
        payinTotal: payinTotal,
        payoutTotal: payoutTotal,
        lineItems: [
          {
            code: unitType,
            unitPrice: unitPrice,
            quantity: 1,
            lineTotal: estimatedMainPrice(unitPrice, 1, 1),
          },
        ],
        transitions: [
          {
            createdAt: now,
            by: TX_TRANSITION_ACTOR_CUSTOMER,
            transition: TRANSITION_REQUEST_PAYMENT,
          },
        ],
      },
    }
    : {
      id: new UUID('estimated-transaction'),
      type: 'transaction',
      attributes: {
        createdAt: now,
        lastTransitionedAt: now,
        lastTransition: TRANSITION_REQUEST_PAYMENT,
        payinTotal: payinTotal,
        payoutTotal: payoutTotal,
        lineItems: [
          ...listOfFormattedPriceAddons,
          ...listOfFormattedDiscountPriceAddons,
          ...chargebeeAddons,
          ...corporateAddons,
          {
            code: unitType,
            includeFor: ['customer', 'provider'],
            unitPrice: unitPrice,
            quantity: new Decimal(quantity),
            seats: seats,
            lineTotal: estimatedMainPrice(unitPrice, quantity, seats),
            reversal: false,
          },
        ],
        transitions: [
          {
            createdAt: now,
            by: TX_TRANSITION_ACTOR_CUSTOMER,
            transition: TRANSITION_REQUEST_PAYMENT,
          },
        ],
      },
      booking: {
        id: new UUID('estimated-booking'),
        type: 'booking',
        attributes: {
          start: bookingStart,
          end: bookingEnd,
        },
      },
    };
};

const EstimatedBreakdownMaybe = props => {
  const {
    unitType,
    unitPrice,
    startDate,
    endDate,
    quantity,
    timeZone,
    priceAddons,
    discountPriceAddons,
    seats,
  } = props.bookingData;
  const { isProduct, listingType } = props;
  const isUnits = unitType === LINE_ITEM_UNITS;
  const quantityIfUsingUnits = !isUnits || isInt(quantity) || isFloat(quantity);
  const canEstimatePrice = startDate && endDate && unitPrice && quantityIfUsingUnits;
  const currentUserChargebeeId = false;
  const currentUserIsCorporateUser = !!props?.currentUser?.attributes?.profile?.protectedData
    ?.corporate_user;
  const currentUserEndDate = props.currentUser?.attributes?.profile?.protectedData?.FutureDate;
  const todayDate = moment(new Date()).format('MM/DD/YYYY');
  if (!isProduct && !canEstimatePrice) {
    return null;
  }
  const tx = estimatedTransaction(
    currentUserChargebeeId,
    currentUserIsCorporateUser,
    currentUserEndDate,
    todayDate,
    unitType,
    startDate,
    endDate,
    unitPrice,
    quantity,
    priceAddons,
    discountPriceAddons,
    seats || 1,
    isProduct
  );

  return (
    <>
      {isProduct ? (
        <OrderBreakdown className={css.receipt} unitType={unitType} transaction={tx} />
      ) : (
        <BookingBreakdown
          className={css.receipt}
          userRole="customer"
          unitType={unitType}
          transaction={tx}
          booking={tx.booking}
          timeZone={timeZone}
          listing={props.listing}
          listingType={listingType}
        />
      )}
    </>
  );
};

export default EstimatedBreakdownMaybe;

import React, { useState, useEffect, memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { propTypes } from 'util/types';
import { IconSpinner, Button, NamedLink, HorizontalScroll, Loading } from 'components';
import { createSlug } from '../../util/urlHelpers';
import { FormattedMessage } from 'util/reactIntl';
import { getStartHours, isInRange, resetToStartOfDay, dateIsAfter } from '../../util/dates';
import { ensurePrice } from 'util/data';
import { defaultListingCurrency } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { openBookModal } from './ListingPage.data';

import css from './SectionFacilities.css';
import moment from 'moment';
import classNames from 'classnames';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const DEFAULT_DATE = {
  start: moment()
    .set({ hour: 0, minute: 0, second: 0 })
    .toISOString(),
  end: moment()
    .set({ hour: 23, minute: 59, second: 59 })
    .toISOString(),
};

const SectionFacilities = props => {
  const {
    listing,
    onFetchAuthorFacilities,
    authorFacilities,
    authorFacilitiesIsLoading,
    onFetchTimeSlots,
    facilityTimeslots,
    bookedTimeslots,
    intl,
    addBookingData,
    isMobileLayout,
    isTimeSlotesLoading,
    isOwnListing,
    isClosed,
    history,
    location,
    setMaxDay,
    maxDay,
  } = props;

  const currentListingId = listing.id.uuid;
  const [showAll, setShowAll] = useState(true);
  const date = DEFAULT_DATE;

  const timeSlots = useMemo(
    () =>
      facilityTimeslots.filter(
        (v, i, a) => facilityTimeslots.findIndex(v2 => v2.id === v.id) === i
      ),
    [facilityTimeslots]
  );

  const bookedtimeSlots = useMemo(
    () =>
      bookedTimeslots.filter((v, i, a) => bookedTimeslots.findIndex(v2 => v2.id === v.id) === i),
    [bookedTimeslots]
  );

  // useEffect(() => {
  //   const authorId = listing?.author?.id?.uuid;
  //   authorId && onFetchAuthorFacilities(authorId, date);
  // }, [listing.author.id.uuid, date, maxDay]);

  useEffect(() => {
    if (authorFacilities && !!authorFacilities.length) {
      authorFacilities.forEach(f => {
        const timezone = f.attributes?.availabilityPlan?.timezone;
        const start = moment(date)
          .clone()
          .tz(timezone)
          .toDate();
        const end = moment(date)
          .clone()
          .tz(timezone)
          .add(maxDay, 'days')
          .toDate();
        const params = {
          listingId: f.id.uuid,
          start,
          end,
          timezone,
        };
        onFetchTimeSlots(params);
      });
    }
  }, [authorFacilities.length, date, showAll, maxDay]);

  const priceData = (price, intl) => {
    if (price && defaultListingCurrency.includes(price.currency)) {
      const formattedPrice = formatMoney(intl, price, 0, 0);
      return { formattedPrice, priceTitle: formattedPrice };
    } else if (price) {
      return {
        formattedPrice: `(${price.currency})`,
        priceTitle: `Unsupported currency (${price.currency})`,
      };
    }
    return {};
  };

  const authorFacilityLength = authorFacilities?.length;

  const getListing = useCallback(
    id => authorFacilityLength && authorFacilities.filter(l => l.id.uuid === id)[0],
    [authorFacilityLength]
  );

  const getTimeSlots = useCallback((timeSlots, date, timeZone) => {
    return timeSlots && timeSlots[0]
      ? timeSlots.filter(t =>
          isInRange(date, t.attributes.start, t.attributes.end, 'day', timeZone)
        )
      : [];
  }, []);

  const bookedgetTimeSlots = useCallback((bookedtimeSlots, date, timeZone) => {
    return bookedtimeSlots && bookedtimeSlots[0]
      ? bookedtimeSlots.filter(t => isInRange(date, t.start, t.end, 'day', timeZone))
      : [];
  }, []);

  const getAvailableStartTimes = useCallback(
    (intl, timeZone, bookingStart, timeSlotsOnSelectedDate) => {
      if (timeSlotsOnSelectedDate.length === 0 || !timeSlotsOnSelectedDate[0] || !bookingStart)
        return [];

      const bookingStartDate = resetToStartOfDay(bookingStart, timeZone);

      const allHours = timeSlotsOnSelectedDate.reduce((availableHours, t) => {
        const startDate = t?.attributes?.start || t?.start || '';
        const endDate = t?.attributes?.end || t?.end || '';
        const nextDate = resetToStartOfDay(bookingStartDate, timeZone, 1);

        // If the start date is after timeslot start, use the start date.
        // Otherwise use the timeslot start time.
        const startLimit = dateIsAfter(bookingStartDate, startDate) ? bookingStartDate : startDate;

        // If date next to selected start date is inside timeslot use the next date to get the hours of full day.
        // Otherwise use the end of the timeslot.
        const endLimit = dateIsAfter(endDate, nextDate) ? nextDate : endDate;

        const hours = getStartHours(intl, timeZone, startLimit, endLimit);
        return availableHours.concat(hours);
      }, []);
      return allHours;
    },
    []
  );

  let reftimeSlotsOnSelectedDate = [];
  for (let i = 0; i < maxDay; i++) {
    reftimeSlotsOnSelectedDate.push(
      timeSlots.map(b => {
        return {
          id: b.id,
          date: moment()
            .add(i, 'days')
            .format('ddd, DD/MM/YYYY'),
          start:
            i === 0
              ? moment().toDate()
              : moment()
                  .add(i, 'days')
                  .toDate(),
          bookingSlotes: getTimeSlots(
            b.bookingSlotes,
            i === 0
              ? moment().toDate()
              : moment()
                  .add(i, 'days')
                  .toDate(),
            b.timezone
          ),
          timezone: b.timezone,
        };
      })
    );
  }

  let bookedreftimeSlotsOnSelectedDate = [];
  for (let i = 0; i < maxDay; i++) {
    bookedreftimeSlotsOnSelectedDate.push(
      bookedtimeSlots.map(b => {
        return {
          id: b.id,
          date: moment()
            .add(i, 'days')
            .format('ddd, DD/MM/YYYY'),
          start:
            i === 0
              ? moment().toDate()
              : moment()
                  .add(i, 'days')
                  .toDate(),
          bookingSlotes: bookedgetTimeSlots(
            b.bookingSlotes,
            i === 0
              ? moment().toDate()
              : moment()
                  .add(i, 'days')
                  .toDate(),
            b.timezone
          ),
          timezone: b.timezone,
        };
      })
    );
  }

  let refavailableStartTimes = [];
  reftimeSlotsOnSelectedDate.forEach(b => {
    b.map(a => {
      refavailableStartTimes.push({
        id: a.id,
        date: a.date,
        timezone: a.timezone,
        bookingSlotes: getAvailableStartTimes(intl, a.timezone, a.start, a.bookingSlotes),
      });
    });
  });

  let bookedavailableStartTimes = [];
  bookedreftimeSlotsOnSelectedDate.forEach(b => {
    b.map(a => {
      bookedavailableStartTimes.push({
        id: a.id,
        date: a.date,
        timezone: a.timezone,
        bookingSlotes:
          a.bookingSlotes.length && a.bookingSlotes[0]
            ? getAvailableStartTimes(intl, a.timezone, a.start, a.bookingSlotes)
            : [],
      });
    });
  });

  let mainAvailableStartTimes = useMemo(
    () =>
      refavailableStartTimes.map(b => {
        return {
          id: b.id,
          date: b.date,
          timezone: b.timezone,
          bookingSlotes: b?.bookingSlotes.filter(
            s => s.timeOfDay.split(':')[1].split(' ')[0] === '00'
          ),
        };
      }),
    [refavailableStartTimes]
  );

  let bookedmainAvailableStartTimes = useMemo(
    () =>
      bookedavailableStartTimes.map(b => {
        return {
          id: b.id,
          date: b.date,
          timezone: b.timezone,
          bookingSlotes: b?.bookingSlotes.filter(
            s => s.timeOfDay.split(':')[1].split(' ')[0] === '00'
          ),
        };
      }),
    [bookedavailableStartTimes]
  );

  var groupBy = (objectArray, property) => {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  };
  const groupByDate = groupBy(mainAvailableStartTimes, 'date');
  const bookedgroupByDate = groupBy(bookedmainAvailableStartTimes, 'date');

  let facilityData = Object.keys(groupByDate).map(key => ({ date: key, name: groupByDate[key] }));

  let bookedfacilityData = Object.keys(bookedgroupByDate).map(key => ({
    date: key,
    name: bookedgroupByDate[key],
  }));
  const getAvailability = (data, id, slug, isToday, currentHour, hour) => {
    let avalibale = true;
    if (isToday && Number(currentHour) + 1 >= hour) avalibale = false;

    return data && avalibale && currentListingId !== id ? (
      <NamedLink className={css.link} name="ListingPage" params={{ id, slug }}>
        {data}
      </NamedLink>
    ) : data && avalibale ? (
      <div>{data}</div>
    ) : (
      <> </>
    );
  };

  const getFacility = (data, bookedName) => {
    const listing = getListing(data.id);
    if (!listing) {
      return <Loading />;
    }
    const isToday =
      moment(data?.date?.split(' ')[1], 'DD/MM/YYYY').format('DD/MM/YYYY') ===
      moment().format('DD/MM/YYYY');
    const currentHour = moment()
      .tz(data?.timezone)
      .format('HH');

    let availbaleStartTime =
      data?.bookingSlotes &&
      data?.bookingSlotes.map(b => {
        const key =
          b.timeOfDay.split(' ')[1] === 'PM' && Number(b.timeOfDay.split(':')[0] < 12)
            ? Number(b.timeOfDay.split(':')[0]) + 12
            : Number(b.timeOfDay.split(':')[0]);
        return { [key]: b.timestamp };
      });
    let bookedStartTime =
      bookedName?.bookingSlotes && bookedName?.bookingSlotes.length !== 0
        ? bookedName.bookingSlotes.map(b => {
            const key =
              b.timeOfDay.split(' ')[1] === 'PM' && Number(b.timeOfDay.split(':')[0] < 12)
                ? Number(b.timeOfDay.split(':')[0]) + 12
                : Number(b.timeOfDay.split(':')[0]);
            return { [key]: b.timestamp };
          })
        : [];
    const price = listing?.attributes?.price;
    const currentPrice = ensurePrice(price);
    const { formattedPrice } = priceData(currentPrice, intl);
    const slug = createSlug(listing.attributes.title);

    return (
      <tr key={data.id}>
        <td className={css.title}>
          <div className={css.facilityDateWrap}>
            <div className={css.facilityName}>
              <NamedLink name="ListingPage" params={{ id: listing.id.uuid, slug }}>
                {listing?.attributes?.title}
              </NamedLink>
              <div className={`${css.tooltipWrap} ${css.facilityNameTooltip}`}>
                <span>{listing?.attributes?.title}</span>
              </div>
            </div>
            <span className={css.facilityDate}>{data.date}</span>
          </div>
        </td>
        {[...Array(18)].map((e, i) => {
          const bookingStatus = bookedStartTime.find(obj => obj[i + 6])?.[i + 6]
            ? 'Booked'
            : availbaleStartTime.find(obj => obj[i + 6])?.[i + 6] 
            // ||
            //   (isToday && Number(currentHour) + 1 < i + 6)
            ? getAvailability(formattedPrice, listing.id.uuid, slug, isToday, currentHour, i + 6)
            : false;

          const BookingTime = bookedStartTime.find(obj => obj[i + 6])?.[i + 6];
          const availbaleTime = availbaleStartTime.find(obj => obj[i + 6])?.[i + 6];

          const cssClass = bookedStartTime.find(obj => obj[i + 6])?.[i + 6]
            ? css.booked
            : availbaleStartTime.find(obj => obj[i + 6])?.[i + 6] 
            // ||
            //   (isToday && Number(currentHour) + 1 < i + 6)
            ? css.availbale
            : css.unavailbale;

          return (
            <td
              key={i + 6}
              className={classNames(
                //   {
                //     [css.booked]:
                //       bookedStartTime.length !== 0 &&
                //       bookedStartTime.find(obj => obj[i + 6])?.[i + 6],
                //     [css.availbale]:
                //       (!bookedStartTime.find(obj => obj[i + 6])?.[i + 6] &&
                //         !!availbaleStartTime.find(obj => obj[i + 6])?.[i + 6]) ||
                //       (isToday && Number(currentHour) + 1 < i + 6),
                //     [css.unavailbale]:
                //       (!bookedStartTime.find(obj => obj[i + 6])?.[i + 6] &&
                //         !availbaleStartTime.find(obj => obj[i + 6])?.[i + 6]) ||
                //       (isToday && Number(currentHour) + 1 >= i + 6),
                //   },
                css.tableTdGap
              )}
              onClick={() => {
                if (availbaleStartTime.find(obj => obj[i + 6])?.[i + 6]) {
                  addBookingData({
                    id: data.id,
                    date: moment(data?.date?.split(' ')[1], 'DD/MM/YYYY')
                      .set('hour', 12)
                      .set('minute', 0)
                      .set('second', 0)
                      .toDate(),
                    start: !!availbaleStartTime.find(obj => obj[i + 6])?.[i + 6]
                      ? availbaleStartTime.find(obj => obj[i + 6])?.[i + 6]
                      : null,
                  });
                }
                isMobileLayout && openBookModal(isOwnListing, isClosed, history, location);
              }}
            >
              <Link to={`/l/${slug}/${listing.id.uuid}`}>
                <div className={cssClass}>
                  <div className={css.tooltipWrap}>
                    <span>
                      {bookingStatus === 'Booked'
                        ? BookingTime && moment(BookingTime).format('LT')
                        : availbaleTime && moment(availbaleTime).format('LT')}
                    </span>
                    <span>{bookingStatus}</span>
                  </div>
                </div>
              </Link>
            </td>
          );
        })}
      </tr>
    );
  };
  const facilityComponet = facilityData => {
    return facilityData.map((t, i) => {
      const bookedData = bookedfacilityData[i];
      const bookedName = bookedData?.name || [];

      return t.name.map((t, i) => getFacility(t, bookedName[i]));
    });
  };

  return (
    <div className={classNames(!!isMobileLayout && css.facilityiesWrapper)}>
      {authorFacilitiesIsLoading || !!isTimeSlotesLoading ? (
        <IconSpinner className={css.spinner} />
      ) : (
        !!authorFacilities.length && (
          <>
            {/* <div className={css.filter}>
              <span className={css.indicatorText}>Today</span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="84"
                className={css.filterIcon}
                height="50"
                viewBox="0 0 84 50"
                fill="none"
              >
                <path
                  d="M43.1491 49.3741L0.757125 2.92422L83.2855 0.961993L43.1491 49.3741Z"
                  fill="#0058FA"
                />
              </svg>
            </div> */}
            {facilityData && facilityData.length !== 0 && (
              <>
                {/* {!!isMobileLayout ? (
                  facilityComponet(facilityData)
                ) : ( */}
                <HorizontalScroll
                  className={css.horizontalScroll}
                  scrollClassName={css.scrollControl}
                >
                  <table cellPadding="1">
                    <thead>
                      <tr>
                        <th className={css.date}>{'Facilities'}</th>
                        {[...Array(18)].map((e, i) => (
                          <th key={i + 6} className={css.td}>{`${i + 6}:00`}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>{facilityComponet(facilityData)}</tbody>
                  </table>
                </HorizontalScroll>
                {/* )} */}
              </>
            )}
            {facilityData && facilityData.length !== 0 && (
              <div className={css.indicatorWrap}>
                <div className={css.indicator}>
                  <span className={css.indicatorIcon_available}></span>
                  <span className={css.indicatorText}>available</span>
                </div>
                <div className={css.indicator}>
                  <span className={css.indicatorIcon_notavailable}></span>
                  <span className={css.indicatorText}>not available</span>
                </div>
                <div className={css.indicator}>
                  <span className={css.indicatorIcon_yourbooking}></span>
                  <span className={css.indicatorText}>your booking</span>
                </div>
              </div>
            )}
          </>
        )
      )}

      {!!authorFacilities.length && facilityData && facilityData.length !== 0 && (
        <div className={css.viewMoreButtonWrapper}>
          <Button
            onClick={() => {
              setShowAll(prevState => !prevState);
              setMaxDay(prevState => (prevState === 3 ? 5 : (prevState === 5 ? 3 : 9)));
            }}
          >
            {!showAll ? (
              <FormattedMessage id="ListingPage.viewMoreDates" />
            ) : (
              <FormattedMessage id="ListingPage.viewLessDates" />
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

SectionFacilities.propTypes = {
  listing: propTypes.listing,
  fetchAuthorClasses: PropTypes.func,
  authorClasses: PropTypes.arrayOf(propTypes.listing),
  authorClassesIsLoading: PropTypes.bool,
};

// export default SectionFacilities;

export default memo(SectionFacilities);

import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  Button,
  Form,
  FieldCurrencyInput,
  FieldTextInput,
  SecondaryButton,
} from '../../components';
import css from './EditListingPricingForm.css';
import { uid } from 'react-uid';
import { LISTING_TYPES } from 'util/constants';

const { Money } = sdkTypes;
const MAX_FIELDS = 5;
const MAX_FIELDS_DISCOUNT_ADDONS = 2;

const textRequired = value => (value ? undefined : 'Required');

const discountBetween = value =>
  value.match(/^[1-9][0-9]?$/) ? undefined : 'Enter value between 0 to 99';

const DESCRIPTION_MAX_LENGTH = 250;

export const EditListingPricingFormComponent = props => {
  const [currencies, setCurrencies] = useState(
    props?.initialValues?.currency || props?.initialValues?.price?.currency || config.currency
  );

  const isMembership = props.listingType === LISTING_TYPES.MEMBERSHIP;

  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          form,
        } = formRenderProps;

        const {
          mutators: { push, pop },
        } = form;

        const currencyType = [
          {
            currency: config.currencySGD,
            // symbol: '$',
          },
          {
            currency: config.currencyAUD,
            symbol: '$',
          },
          {
            currency: config.currencyGBP,
            symbol: '£',
          },
          {
            currency: config.currencyUSD,
            symbol: '$',
          },
        ];

        const unitType = config.bookingUnitType;
        const isNightly = unitType === LINE_ITEM_NIGHT;
        const isDaily = unitType === LINE_ITEM_DAY;
        const isProduct = props.listingType === 'product';

        const translationKey = isProduct
          ? 'EditListingPricingForm.pricePerProduct'
          : isNightly
          ? 'EditListingPricingForm.pricePerNight'
          : isDaily
          ? 'EditListingPricingForm.pricePerDay'
          : 'EditListingPricingForm.pricePerUnit';

        const pricePerUnitMessage = intl.formatMessage({
          id: translationKey,
        });

        const pricePerMembershipMessage = intl.formatMessage({
          id: 'EditListingPricingForm.pricePerMembership',
        });

        const pricePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.priceInputPlaceholder',
        });

        const priceRequired = validators.required(
          intl.formatMessage({
            id: 'EditListingPricingForm.priceRequired',
          })
        );
        const minPrice = new Money(config.listingMinimumPriceSubUnits, currencies);
        const minPriceRequired = validators.moneySubUnitAmountAtLeast(
          intl.formatMessage(
            {
              id: 'EditListingPricingForm.priceTooLow',
            },
            {
              minPrice: formatMoney(intl, minPrice),
            }
          ),
          config.listingMinimumPriceSubUnits
        );
        const priceValidators = config.listingMinimumPriceSubUnits
          ? validators.composeValidators(priceRequired, minPriceRequired)
          : priceRequired;

        const packagePriceValidators = config.listingMinimumPriceSubUnits ? minPriceRequired : null;

        const sessionsLabel = intl.formatMessage({
          id:
            props.listingType !== 'product'
              ? 'EditListingPricingForm.sessionsLabel'
              : 'EditListingPricingForm.sessionsProductLabel',
        });
        const sessionsPlaceholder = intl.formatMessage({
          id: 'EditListingPricingForm.sessionsPlaceholder',
        });
        const packagePricePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.packagePricePlaceholder',
        });

        const packagePriceLabelMessage = intl.formatMessage({
          id: 'EditListingPricingForm.packagePriceLabelMessage',
        });

        const descriptionMessage = intl.formatMessage({
          id: 'EditListingPricingForm.packageDescriptionLabel',
        });
        const descriptionPlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.packageDescriptionPlaceholder',
        });

        const maxLengthMessage = intl.formatMessage(
          { id: 'EditListingPricingForm.descriptionMaxLength' },
          {
            maxLength: DESCRIPTION_MAX_LENGTH,
          }
        );

        const subscriptionlabel = intl.formatMessage({
          id: 'EditListingPricingForm.membershipDetail',
        });

        const maxLength250Message = validators.maxLength(maxLengthMessage, DESCRIPTION_MAX_LENGTH);

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;
        const { updateListingError, showListingsError } = fetchErrors || {};
        return (
          <Form onSubmit={handleSubmit} className={classes}>
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.updateFailed" />
              </p>
            ) : null}
            {showListingsError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.showListingFailed" />
              </p>
            ) : null}

            <div className={css.editListingTypeWrap}>
              <label className={css.editListingLabel}>Currency</label>
              <div className={css.editListingCardWrap}>
                {currencyType.map(type => (
                  <div
                    className={classNames(
                      css.buttonMain,
                      currencies === type.currency && css.selected
                    )}
                  >
                    <div
                      onClick={() => {
                        setCurrencies(type.currency);
                        form.change('currency', type.currency);
                        props?.onCurrencychange && props.onCurrencychange(type.currency);
                        // setErrorMessage(false);
                      }}
                      className={css.buttonWrapper}
                    >
                      <div className={css.editListingTypeText}>{type.currency}</div>
                      <div className={css.svgWrapper}>{type.symbol}</div>
                    </div>
                    <div className={css.checkMark}>
                      <svg
                        width="12"
                        height="10"
                        viewBox="0 0 12 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 5.8L3.85714 9L11 1"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          fill="transparent"
                        />
                      </svg>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <FieldCurrencyInput
              id="price"
              name={'price'}
              className={css.priceInput}
              autoFocus
              label={pricePerUnitMessage}
              placeholder={pricePlaceholderMessage}
              currencyConfig={config.formatedCurrencyConfig(currencies)}
              validate={priceValidators}
              onPriceChange={props?.onPriceChange}
            />

            {isProduct && (
              <FieldTextInput
                id="stock"
                name={'stock'}
                className={css.priceInput}
                label={intl.formatMessage({
                  id: 'EditListingPricingForm.stockperProduct',
                })}
                placeholder={'Enter total stock'}
                // validate={priceValidators}
                onPriceChange={props?.onPriceChange}
              />
            )}

            <FieldArray name={'addons'}>
              {({ fields }) => {
                return fields.map((name, index) => {
                  if (index < MAX_FIELDS) {
                    return (
                      <div className={css.addonWrap}>
                        <FieldTextInput
                          key={uid(`${name}_${index}`)}
                          id="addons-label"
                          name={`${name}.label`}
                          type="textarea"
                          rows="3"
                          className={css.addonText}
                          autoFocus
                          label={<FormattedMessage id={'EditListingPricingPanel.textLabel'} />}
                          validate={textRequired}
                        />

                        <FieldCurrencyInput
                          id="addons-price"
                          name={`${name}.price`}
                          className={css.addonPrice}
                          label={pricePerUnitMessage}
                          placeholder={pricePlaceholderMessage}
                          currencyConfig={config.formatedCurrencyConfig(currencies)}
                          validate={priceValidators}
                        />
                      </div>
                    );
                  } else {
                    return null;
                  }
                });
              }}
            </FieldArray>

            <FieldArray name={'discountaddons'}>
              {({ fields }) => {
                return fields.map((name, index) => {
                  if (index < MAX_FIELDS_DISCOUNT_ADDONS) {
                    return (
                      <div className={css.addonWrap}>
                        <FieldTextInput
                          key={uid(`${name}_${index}`)}
                          id="discountaddons-label"
                          name={`${name}.label`}
                          type="textarea"
                          rows="3"
                          className={css.addonText}
                          autoFocus
                          label="Discount Add-on name"
                          validate={textRequired}
                        />

                        <FieldTextInput
                          id="discountaddons-price"
                          name={`${name}.price`}
                          type="number"
                          className={css.addonPrice}
                          label="Discount Add-on Price"
                          placeholder="Add discount..."
                          validate={validators.composeValidators(textRequired, discountBetween)}
                        />
                      </div>
                    );
                  } else {
                    return null;
                  }
                });
              }}
            </FieldArray>

            <div className={css.mutateBtns}>
              <SecondaryButton
                disabled={
                  formRenderProps.values.addons &&
                  formRenderProps.values.addons.length === MAX_FIELDS
                }
                type="button"
                onClick={() => push('addons', undefined)}
              >
                <FormattedMessage id={'EditListingPricingPanel.addAddon'} />
              </SecondaryButton>

              <SecondaryButton
                disabled={
                  !formRenderProps.values.addons || formRenderProps.values.addons.length === 0
                }
                type="button"
                onClick={() => pop('addons')}
              >
                <FormattedMessage id={'EditListingPricingPanel.removeAddon'} />
              </SecondaryButton>
            </div>

            <div className={css.mutateBtns}>
              <SecondaryButton
                disabled={
                  formRenderProps.values.discountaddons &&
                  formRenderProps.values.discountaddons.length === MAX_FIELDS_DISCOUNT_ADDONS
                }
                type="button"
                onClick={() => push('discountaddons', undefined)}
              >
                Add Discount Add-on
              </SecondaryButton>

              <SecondaryButton
                disabled={
                  !formRenderProps.values.discountaddons ||
                  formRenderProps.values.discountaddons.length === 0
                }
                type="button"
                onClick={() => pop('discountaddons')}
              >
                Remove Discount Add-on
              </SecondaryButton>
            </div>

            {!!isMembership ? (
              <div className={css.membershipWrapper}>
                <h2>
                  <FormattedMessage id="EditListingPricingForm.membership" />
                </h2>

                <FieldCurrencyInput
                  id="membershipPrice"
                  name="membershipPrice"
                  className={css.priceInput}
                  label={pricePerMembershipMessage}
                  placeholder={pricePlaceholderMessage}
                  currencyConfig={config.formatedCurrencyConfig(currencies)}
                  onPriceChange={props?.onmembershipPriceChange}
                />
                <div className={css.priceText}>
                  This monthly subscription amount will be charged every month from the user who
                  will buy the subscrption.
                </div>

                <FieldTextInput
                  id="subscriptionDescription"
                  name="subscriptionDescription"
                  className={css.packageSessionsField}
                  label={subscriptionlabel}
                  placeholder="Enter description"
                  maxLength={60}
                />
              </div>
            ) : (
              <>
                <h2>
                  <FormattedMessage id="EditListingPricingForm.packages" />
                </h2>
                <h2>
                  <FormattedMessage id="EditListingPricingForm.package1" />
                </h2>
                <FieldTextInput
                  id="package-1-num-of-sessions"
                  name="packages[0][sessions]"
                  className={css.packageSessionsField}
                  type="number"
                  label={sessionsLabel}
                  placeholder={sessionsPlaceholder}
                />
                <FieldCurrencyInput
                  id="package-1-price"
                  name="packages[0][price]"
                  className={css.priceInput}
                  label={packagePriceLabelMessage}
                  placeholder={packagePricePlaceholderMessage}
                  currencyConfig={config.formatedCurrencyConfig(currencies)}
                  validate={packagePriceValidators}
                />
                <FieldTextInput
                  id="package-1-description"
                  name="packages[0][description]"
                  className={css.description}
                  type="textarea"
                  rows="3"
                  label={descriptionMessage}
                  placeholder={descriptionPlaceholderMessage}
                  validate={validators.composeValidators(maxLength250Message)}
                />
                <h2>
                  <FormattedMessage id="EditListingPricingForm.package2" />
                </h2>
                <FieldTextInput
                  id="package-2-num-of-sessions"
                  name="packages[1][sessions]"
                  className={css.packageSessionsField}
                  type="number"
                  label={sessionsLabel}
                  placeholder={sessionsPlaceholder}
                />
                <FieldCurrencyInput
                  id="package-2-price"
                  name="packages[1][price]"
                  className={css.priceInput}
                  label={packagePriceLabelMessage}
                  placeholder={packagePricePlaceholderMessage}
                  currencyConfig={config.formatedCurrencyConfig(currencies)}
                  validate={packagePriceValidators}
                />
                <FieldTextInput
                  id="package-2-description"
                  name="packages[1][description]"
                  className={css.description}
                  type="textarea"
                  rows="3"
                  label={descriptionMessage}
                  placeholder={descriptionPlaceholderMessage}
                  validate={validators.composeValidators(maxLength250Message)}
                />
                <h2>
                  <FormattedMessage id="EditListingPricingForm.package3" />
                </h2>
                <FieldTextInput
                  id="package-3-num-of-sessions"
                  name="packages[2][sessions]"
                  className={css.packageSessionsField}
                  type="number"
                  label={sessionsLabel}
                  placeholder={sessionsPlaceholder}
                />
                <FieldCurrencyInput
                  id="package-3-price"
                  name="packages[2][price]"
                  className={css.priceInput}
                  label={packagePriceLabelMessage}
                  placeholder={packagePricePlaceholderMessage}
                  currencyConfig={config.formatedCurrencyConfig(currencies)}
                  validate={packagePriceValidators}
                />
                <FieldTextInput
                  id="package-3-description"
                  name="packages[2][description]"
                  className={css.description}
                  type="textarea"
                  rows="3"
                  label={descriptionMessage}
                  placeholder={descriptionPlaceholderMessage}
                  validate={validators.composeValidators(maxLength250Message)}
                />
              </>
            )}

            <div className={css.submitButtonContainer}>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);

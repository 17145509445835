import React, { useMemo } from 'react';
import { LISTING_TYPES } from 'util/constants';
import css from './VendorListings.css';

const VendorCategories = props => {
  const { hasLinks } = props;
  const { isEducationSite, isTennisSite } = props;
  const categories = useMemo(
    () =>
      isEducationSite
        ? [
            { key: LISTING_TYPES.ACADEMY, value: 'academy' },
            { key: LISTING_TYPES.REMOTE_TUTOR, value: 'remote tutor' },
            { key: LISTING_TYPES.TUTOR, value: 'tutor' },
            { key: LISTING_TYPES.COURSES, value: 'classes' },
            { key: LISTING_TYPES.EDUCATION_COURSES, value: 'courses' },
            { key: LISTING_TYPES.HOURLY_SESSION, value: 'hourly session' },
          ]
        : isTennisSite
        ? [
            { key: LISTING_TYPES.PLAYER_PROFILE, value: 'player-profile' },
            { key: LISTING_TYPES.EVENT, value: 'event' },
            { key: LISTING_TYPES.PRODUCT, value: 'product' },
          ]
        : [
            {
              key: LISTING_TYPES.LISTING,
              value: 'Coaches',
              image:
                'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1730258624/probuddy/static/images/VendorCategories/Instructors_keztgb.jpg',
            },
            {
              key: LISTING_TYPES.CLASS,
              value: 'Group Classes',
              image:
                'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1730258227/probuddy/static/images/VendorCategories/groupClasses_hqtuvn.png',
            },
            {
              key: LISTING_TYPES.EVENT,
              value: 'Events',
              image:
                'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699505661/probuddy/static/images/VendorCategories/events_disdk4.jpg',
            },
            {
              key: LISTING_TYPES.FACILITY,
              value: 'Facilities',
              image:
                'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1730258767/probuddy/static/images/VendorCategories/facilities_zxsggh.jpg',
            },
            {
              key: LISTING_TYPES.MEMBERSHIP,
              value: 'Memberships',
              image:
                'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699505707/probuddy/static/images/VendorCategories/members_g9nbxn.jpg',
            },
          ],
    [isEducationSite]
  );

  let temp = [];
  categories.forEach(item => {
    const resp = props.vendorListings?.filter(
      listing => listing.attributes.publicData.listing_type === item.key
    );

    if (resp.length > 0) {
      temp.push({ key: item.key, value: item.value, image: item.image });
    }
  });

  return (
    // <div className={css.vendorCatagoryWrapper}>
    //   <div className={css.vendorListingsButtonWrapper}>
    //     {temp?.length === 1
    //       ? props.getListingCategory(temp[0])
    //       : temp?.map((category, index) => (
    //           <div
    //             className={css.vendorListingsButton}
    //             onClick={() => props.getListingCategory(category)}
    //             key={index}
    //           >
    //             {category.value}
    //           </div>
    //         ))}
    //   </div>
    // </div>
    <div className={css.cardWrapper}>
      <div className={css.serviceCard}>
        {temp?.length === 1
          ? props.getListingCategory(temp[0])
          : temp?.map((category, index) => {
              return (
                <div
                  className={css.sreviceCardWrap}
                  onClick={() => props.getListingCategory(category)}
                  key={index}
                >
                  <img className={css.serviceImage} src={category.image} alt="name" />
                  <h3 className={css.serviceText}>{category.value}</h3>
                  <div className={css.btnView}>View</div>
                </div>
              );
            })}
        {hasLinks && hasLinks.length === 1 && (
          <div className={css.sreviceCardWrap} onClick={() => props.getListingCategory('LINKS')}>
            <img
              className={css.serviceImage}
              src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699505732/probuddy/static/images/VendorCategories/website_bygiky.png"
              alt="name"
            />
            <h3 className={css.serviceText}>Links</h3>
            <div className={css.btnView}>View</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VendorCategories;

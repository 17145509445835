import React, { useState } from 'react';
import Price from 'react-price';
import css from './ListingFlipCard.css';
import starRatingIcon from '../../../assets/HomePage/starRatingIcon.svg';
import { NamedRedirect, ResponsiveImage } from '../..';
import moment from 'moment';
import classNames from 'classnames';
import { createSlug } from 'util/urlHelpers';
import { getRatingFromReviews } from 'containers/ListingPage/helper';
import { ensurePrice } from 'util/data';
import { priceData } from 'util/currency';
import { ListingType } from 'containers';
import { LISTING_TYPES } from 'util/constants';

const ListingFlipCard = props => {
  const {
    // description,
    // images,
    // ratings,
    // formattedPrice,
    pl,
    intl,
    // title,
    // address,
    // discountPrice,
    listingType,
    chargebeeData,
    // setRedirect,
    isEducationSite,
    isTennisSite,
  } = props;

  const [isRedirect, setRedirect] = useState(false);

  const title = pl.attributes.title;
  const description = pl.attributes?.description;
  const author = pl.author;
  const providerName = pl.attributes.publicData?.provider_name;
  const images = pl?.images || [];
  const id = pl.id.uuid;
  const slug = createSlug(pl.attributes.title);
  // const rating = getRatingFromReviews(reviews).toFixed(2);
  const totalBooking = pl.attributes.publicData?.total_bookings || 0;
  const address = pl.attributes?.publicData?.location?.address;
  const price = pl.attributes?.price;
  const ratings = pl.attributes.publicData.rating;
  const otherImages = pl?.images?.slice(0, 6);
  const todayDate = moment(new Date()).format('MM/DD/YYYY');
  const hasSubscription = pl.attributes.publicData.has_subscription;
  const sideImages = images.length > 4 ? images.slice(1, 5) : images.slice(0, 4);

  const currentPrice = ensurePrice(price);
  const { formattedPrice } = priceData(currentPrice, intl);

  const { discountPrice } = priceData(currentPrice, intl, 'discount');

  return (
    // <div className={css.newHomeMain}>
    isRedirect ? (
      <NamedRedirect name="ListingPage" params={{ id, slug }} />
    ) : (
      <div className={css.homeCardMain} onClick={() => setRedirect(true)}>
        <div className={classNames(css.homeCardHead, isEducationSite && css.eduColor)}>
          <div className={css.homeHeadImage}>
            <ResponsiveImage
              src={images[0]}
              alt="authorProfileImg"
              image={images[0]}
              // variants={['scaled-small', 'square-small', 'square-small2x']}
              variants={['scaled-medium', 'scaled-small', 'scaled-small2x']}
              onClick={() => setRedirect(true)}
            />
          </div>
        </div>
        <div className={css.homeHeadDetail}>
          <h5 className={css.frontHead}>{title}</h5>
          <div className={css.headSports}>
            {listingType !== LISTING_TYPES.PLAYER_PROFILE && formattedPrice && (
              <span className={css.headSGD}>
                {' '}
                {chargebeeData?.ChargebeeId &&
                todayDate <= chargebeeData?.FutureDate &&
                hasSubscription ? (
                  <>
                    <Price cost={formattedPrice} type="old" />
                    <Price cost={discountPrice} />
                  </>
                ) : (
                  <Price cost={formattedPrice} />
                )}
              </span>
            )}

            {pl.attributes.publicData.sub_category instanceof Array ? (
              pl.attributes.publicData?.sub_category?.slice(0, 3)?.map((subCat, i) => (
                <span key={i} className={css.subCategoryText}>
                  {subCat}
                </span>
              ))
            ) : (
              <span className={css.subCategoryText}>{pl.attributes.publicData.sub_category}</span>
            )}

            {/* <span className={css.sport1}>Tennis</span>
              <span className={css.sport2}>Padel Tennis</span> */}
          </div>

          {/* {listingType !== LISTING_TYPES.PLAYER_PROFILE && formattedPrice && (
            <div className={css.headRate}>
              <div className={css.priceWrapper}>
                <div className={css.priceProduct}>
                  {chargebeeData?.ChargebeeId &&
                  todayDate <= chargebeeData?.FutureDate &&
                  hasSubscription ? (
                    <>
                      <Price cost={formattedPrice} type="old" />
                      <Price cost={discountPrice} />
                    </>
                  ) : (
                    <Price cost={formattedPrice} />
                  )}
                </div>
              </div>
            </div>
          )} */}
        </div>

        <div className={css.homeDetails}>
          <div className={css.detailLocation}>{address}</div>
          <p className={css.detailDesc}>{description}</p>
          <div className={css.detailsImageGallery}>
            {!!sideImages &&
              !!sideImages.length &&
              sideImages.slice(0, 3).map((image, index) => {
                return (
                  <div key={index} className={css.cardImgWrapper}>
                    <ResponsiveImage
                      className={css.itemImg}
                      src={image}
                      image={image}
                      variants={['scaled-small', 'scaled-small2x']}
                      alt="title"
                    />
                  </div>
                );
              })}
          </div>
        </div>

        <div className={css.homeButtonGroup}>
          <button className={css.buttonMessage}>Message</button>
          <button className={css.buttonBook}>Book Now</button>
        </div>
        
          {pl.attributes.publicData?.has_premium_membership && (
            <div className={css.homePremium}>
              {
                pl.attributes.publicData?.has_premium_membership && (
                  <div className={css.ratingTextWrap}>
                    <img src={starRatingIcon} alt={' '} />
                    <span className={css.ratingText}>Premium Member</span>
                  </div>
                )
              }
            </div>
          )}      
      </div>
    )
  );
};

export default ListingFlipCard;
